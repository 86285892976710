import c1audio from "./1/audio.flac"
import c1 from "./1/transcripts.json"

import c2audio from "./2/audio.flac"
import c2 from "./2/transcripts.json"

import c3audio from "./3/audio.flac"
import c3 from "./3/transcripts.json"

import c4audio from "./4/audio.flac"
import c4 from "./4/transcripts.json"

const examples = [
  {
    name: "Example 1",
    audio: c1audio,
    transcripts: c1
  },
  {
    name: "Example 2",
    audio: c2audio,
    transcripts: c2
  },
  {
    name: "Example 3",
    audio: c3audio,
    transcripts: c3
  },
  {
    name: "Example 4",
    audio: c4audio,
    transcripts: c4
  }
];

export default examples;