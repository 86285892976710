import MediaStreamRecorder from "msr";
import audioBufferToWav from "audiobuffer-to-wav";
import concatAudioBuffers from "concat-audio-buffers";

navigator.getUserMedia =
  navigator.getUserMedia ||
  navigator.webkitGetUserMedia ||
  navigator.mozGetUserMedia ||
  navigator.msGetUserMedia;

class MyMediaRecorder {
  constructor(onStart, onData, onStop, timeSlice, options, constraints) {
    this.onStart = onStart;
    this.onData = onData;
    this.onStop = onStop;
    this.options = options;
    this.constraints = constraints;
    this.timeSlice = timeSlice;
    this.mediaRec = null;
    this.startTime = null;
    this.chunks = [];
    this.stream = null;
    this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    this.analyser = this.audioCtx.createAnalyser();
  }

  startRecording() {
    this.startTime = Date.now();

    if (this.mediaRec) {
      if (this.audioCtx && this.audioCtx.state === "suspended") {
        this.audioCtx.resume();
      }

      if (this.mediaRec && this.mediaRec.state === "paused") {
        this.mediaRec.resume();
        return;
      }

      if (
        this.audioCtx &&
        this.mediaRec &&
        this.mediaRec.state === "inactive"
      ) {
        if (this.onStart) {
          this.onStart();
        }
        this.audioCtx.resume().then(() => {
          this.mediaRec.start(this.timeSlice);
          var source = this.audioCtx.createMediaStreamSource(this.stream);
          source.connect(this.analyser);
        });
      }
    } else {
      if (navigator.mediaDevices) {
        console.log("getUserMedia supported");

        navigator.mediaDevices.getUserMedia(this.constraints).then(str => {
          this.stream = str;

          // if (MediaRecorder.isTypeSupported(this.options.mimeType)) {
          //   this.mediaRec = new MediaRecorder(str, this.options);
          // } else {
          //   this.mediaRec = new MediaRecorder(str);
          // }
          this.mediaRec = new MediaStreamRecorder(str);
          this.mediaRec.mimeType = this.options.mimeType;
          this.mediaRec.bufferSize = this.options.bufferSize;
          this.mediaRec.sampleRate = this.options.sampleRate;
          this.mediaRec.audioChannels = this.options.audioChannels;

          if (this.onStart) {
            this.onStart();
          }

          this.mediaRec.ondataavailable = blob => {
            if (blob) {
              this.chunks.push(blob);
              if (this.onData) {
                this.onData(blob);
              }
            }
          };

          //this.mediaRec.onstop = this.onStopCallback;

          this.audioCtx.resume().then(() => {
            this.mediaRec.start(this.timeSlice);
            var source = this.audioCtx.createMediaStreamSource(this.stream);
            source.connect(this.analyser);
          });
        });
      } else {
        alert("Your browser does not support audio recording");
      }
    }
  }

  stopRecording() {
    if (this.mediaRec && this.mediaRec.state !== "inactive") {
      this.mediaRec.stop();

      this.stream.getAudioTracks().forEach(function (track) {
        track.stop();
      });

      this.mediaRec = null;
      this.resetAnalyser();
      this.onStopCallback();
    }
  }

  onStopCallback() {
    // var calBlob = async (total, curr) => {
    //   if (total) {
    //     return await total.then(async a => {
    //       return await curr.then(b => {
    //         return utils.concat(a, b);
    //       });
    //     });
    //   }
    //   return curr;
    // };

    this.chunks = this.chunks.map(async item => {
      var result = await item.arrayBuffer().then(async data => {
        return await this.audioCtx.decodeAudioData(data);
      });
      return result;
    });

    // var ab = this.chunks.reduce(calBlob);

    // ab.then(x => {
    //   x = audioBufferToWav(x);

    //   var blob = new Blob([new DataView(x)], {
    //     type: this.options.mimeType
    //   });

    //   this.chunks = [];

    //   var blobObject = {
    //     blob: blob,
    //     startTime: this.startTime,
    //     stopTime: Date.now(),
    //     options: this.options,
    //     blobUrl: window.URL.createObjectURL(blob)
    //   };

    //   if (this.onStop) {
    //     this.onStop(blobObject);
    //   }
    // });

    Promise.all(this.chunks).then(buffers => {
      concatAudioBuffers(
        buffers,
        this.options.audioChannels,
        (err, combinedBuffer) => {
          if (err) {
            alert(err)
          } else {
            var x = audioBufferToWav(combinedBuffer);

            var blob = new Blob([new DataView(x)], {
              type: this.options.mimeType
            });

            this.chunks = [];

            var blobObject = {
              blob: blob,
              startTime: this.startTime,
              stopTime: Date.now(),
              options: this.options,
              blobUrl: window.URL.createObjectURL(blob)
            };

            if (this.onStop) {
              this.onStop(blobObject);
            }
          }
        }
      )
    })
  }

  getAnalyser() {
    return this.analyser;
  }

  resetAnalyser() {
    this.analyser = this.audioCtx.createAnalyser();
  }

  getAudioCtx() {
    return this.audioCtx;
  }
}

export default MyMediaRecorder;
