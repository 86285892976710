const truncateString = (string, num_char = 10, char = ".") => {
  if (string.length <= 10) {
    return string;
  } else {
    var convertedString = "";
    var half_truncated_str_length = Math.floor((num_char - 3) / 2);
    convertedString += string.substring(0, half_truncated_str_length);
    convertedString += char.repeat(3);
    convertedString += string.substring(
      string.length - half_truncated_str_length,
      string.length
    );
    return convertedString;
  }
};

export { truncateString };
