import React from "react";
import "./style.css";
import Avatar from "../../assets/images/avatar.jpg";
import Background from "../../assets/images/coffee.jpg";
import Background2 from "../../assets/images/code.jpg";

function HomeView() {
  return (
    <div className="HomeView">
      <div className="background" />
      <img className="above" src={Background} alt="background" />
      <img className="below" src={Background2} alt="background2" />
      <div className="content">
        <div className="subset">
          <img className="avatar" src={Avatar} alt="Avatar" />
        </div>
        <div className="subset">
          <div className="quote">
            <p className="greet">Hello, I'm</p>
            <p className="intro">Huy Lê Nguyễn</p>
            <p className="info">
              Data Engineer - Graduated from Ho Chi Minh City University of Technology
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeView;
