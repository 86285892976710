import React from "react";
import { Button, ThemeProvider } from "@material-ui/core";
import theme, { circleTheme } from "./styles.js";

function CircleButton(props) {
  return (
    <ThemeProvider theme={circleTheme}>
      <Button onClick={props.onClick}>{props.children}</Button>
    </ThemeProvider>
  );
}

function MyButton(props) {
  return (
    <ThemeProvider theme={theme}>
      <Button onClick={props.onClick}>{props.children}</Button>
    </ThemeProvider>
  );
}

export default MyButton;
export { CircleButton };
