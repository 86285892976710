import React, { useState, useEffect, useCallback } from "react";
import "./style.css";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretSquareLeft,
  faCaretSquareRight,
} from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../sidebar/sidebar";
import routes from "../../assets/routes";

function Header() {
  let history = useHistory();
  let [bColor, setBColor] = useState("transparent");
  let [shadow, setShadow] = useState("none");
  let [sidebar, setSidebar] = useState(false);
  let [menu, setMenu] = useState();
  let closeSidebar = null;
  let showSidebar = null;
  const darken = () => {
    setBColor("var(--header-solid-background-color)");
    setShadow("none");
  };
  const undarken = () => {
    setBColor("var(--header-background-color)");
    setShadow("none");
  };
  const listenOnScroll = useCallback((e) => {
    if (window.scrollY > 0) {
      darken();
    } else {
      undarken();
    }
  }, []);
  showSidebar = useCallback(() => {
    darken();
    setSidebar(true);
    setMenu(
      <FontAwesomeIcon icon={faCaretSquareRight} onClick={closeSidebar} />
    );
    window.removeEventListener("scroll", listenOnScroll);
  }, [closeSidebar, listenOnScroll]);
  closeSidebar = useCallback(() => {
    listenOnScroll();
    setSidebar(false);
    setMenu(<FontAwesomeIcon icon={faCaretSquareLeft} onClick={showSidebar} />);
    window.addEventListener("scroll", listenOnScroll);
  }, [listenOnScroll, showSidebar]);
  useEffect(() => {
    closeSidebar();
    return function cleanup() {
      window.removeEventListener("scroll", listenOnScroll);
    };
  }, [listenOnScroll, closeSidebar]);
  return (
    <div
      className="header"
      style={{ backgroundColor: bColor, boxShadow: shadow }}
    >
      <div className="subset">
        <button
          className="button title"
          onClick={() => {
            history.push("/");
            closeSidebar();
          }}
        >
          Huy Le Nguyen
        </button>
      </div>
      <div className="subset menu-long">
        {routes.map((value, idx) => {
          return (
            <button
              key={idx}
              className="button"
              onClick={() => {
                history.push(value.path);
              }}
            >
              {value.name}
            </button>
          );
        })}
      </div>
      <div className="subset menu-short">{menu}</div>
      <Sidebar visible={sidebar} closeSidebar={closeSidebar} />
    </div>
  );
}

export default Header;
