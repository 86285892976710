import TextField from "@material-ui/core/TextField";
import { withStyles, createStyles } from "@material-ui/core";

const MyTextField = withStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2),
      "& *": {
        fontFamily: "Nunito",
        fontSize: "1em",
        textTransform: "uppercase",
        fontWeight: "600",
        borderRadius: 5,
      },
    },
  })
)(TextField);

export default MyTextField;
