const axios = require("axios");

const asr = (payload) => {
  let data = new FormData();
  data.append("payload", payload);
  let config = {
    url: "/conformer-recognize",
    baseURL: process.env.REACT_APP_ASR_URL,
    method: "POST",
    transformResponse: [
      function (data) {
        return data.payload;
      },
    ],
    headers: {
      "Content-Type": "multipart/form-data",
    },
    responseType: "json",
    responseEncoding: "utf8",
    data: data,
  };
  return axios.request(config);
};

const asr_segan = (payload) => {
  let data = new FormData();
  data.append("payload", payload);
  let config = {
    url: "/static-segan",
    baseURL: process.env.REACT_APP_ASR_URL,
    method: "POST",
    transformResponse: [
      function (data) {
        return data.payload;
      },
    ],
    headers: {
      "Content-Type": "multipart/form-data",
    },
    responseType: "json",
    responseEncoding: "utf8",
    data: data,
  };
  return axios.request(config);
};

export { asr, asr_segan };
