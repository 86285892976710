import React from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";

function MyFileInput(props) {
  return (
    <div className="fileinput" id={props.id}>
      <input
        type="file"
        accept={props.accept}
        multiple={props.multiple}
        onChange={props.onChange}
        id="file"
      />
      <label htmlFor="file">
        <FontAwesomeIcon icon={faFileUpload} fixedWidth />
        <p>{props.name}</p>
      </label>
      <div onClick={props.onSubmit} className="span mdc-ripple-surface">
        Submit
      </div>
    </div>
  );
}

export default MyFileInput;
