import { createMuiTheme } from "@material-ui/core";

const circleTheme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        height: "fit-content",
        width: "fit-content",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "whitesmoke",
        minWidth: 0,
      },
      text: {
        fontSize: "1.5em",
        borderRadius: "50%",
        color: "var(--red)",
        border: "0.2px solid rgba(0, 0, 0, 0.1)",
        padding: "0.5em",
        height: "fit-content",
        width: "fit-content",
      },
      label: {
        height: "1em",
        width: "1em",
      },
    },
  },
});

const theme = createMuiTheme({
  overrides: {
    MuiButton: {},
  },
});

export { circleTheme };
export default theme;
