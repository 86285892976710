import React, { useState, useEffect } from 'react';
import MyReactMic from '../../components/microphone/mic';
import './style.css';
import MyTextField from '../../components/textfield/textField';
import { CircleButton } from '../../components/buttons/buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faStopCircle } from '@fortawesome/free-solid-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import MyFileInput from '../../components/inputfield/input';
import { asr, asr_segan } from '../../controllers/asr';
import { truncateString } from '../../utils/string';
import Switch from '@material-ui/core/Switch';
import { IconButton, CircularProgress } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';
import io from 'socket.io-client';
import Kofi from '../../assets/images/kofi.png';

import { default as ex1 } from './examples/conformer-subwords/examples';

const sampleRate = 44100; // default sampleRate
const channels = 1; // default channels
const timeSlice = 350;
const mimeType = 'audio/wav';
const bufferSize = 8192;

function AsrView() {
  const [transcript, setTranscript] = useState('');
  const [record, setRecord] = useState(false);
  const [blob, setBlob] = useState(null);
  const [blobUrl, setBlobUrl] = useState(null);
  const [streaming, setStreaming] = useState(false);
  const [noise_filter, setNoise_filter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    if (socket !== null) {
      socket.on('connect', () => {
        console.log('Connected with ', socket.id);
        setLoading(false);
      });
      socket.on('connect_error', (err) => {
        alert('Failed to connect to server');
        socket.disconnect();
        setSocket(null);
        setStreaming(false);
        setLoading(false);
      });
      socket.on('connect_timeout', (err) => {
        alert('Timeout when connecting to server');
        socket.disconnect();
        setSocket(null);
        setStreaming(false);
        setLoading(false);
      });
      socket.on('streaming_response', (payload) => {
        console.log(payload);
        setTranscript((transcript) => transcript.concat(payload));
      });
    }
  }, [socket]);

  useEffect(() => {
    if (blob != null) {
      setBlobUrl(window.URL.createObjectURL(blob));
    }
  }, [blob]);

  function change_to_streaming(streaming) {
    if (streaming) {
      /*
      setLoading(true);
      setSocket(io(process.env.REACT_APP_ASR_URL + "/streaming"));
      */
      alert('Streaming is not supported yet');
    } else {
      /*
      socket.disconnect();
      setSocket(null);
      */
      setStreaming(streaming);
    }
  }

  function startRecording() {
    setTranscript('');
    setRecord(true);
  }

  function stopRecording() {
    setRecord(false);
  }

  function onData(recordedBlob) {
    console.log(recordedBlob);
    if (socket !== null) {
      socket.emit('streaming', recordedBlob);
    }
  }

  function onStop(recordedBlob) {
    setBlob(recordedBlob.blob);
  }

  function toggle() {
    if (record === false) {
      startRecording();
    } else {
      stopRecording();
    }
  }

  function transcribe() {
    if (streaming) {
      alert('Please turn off streaming mode to proceed');
    } else {
      if (blob == null) {
        alert('You need to record your speech');
      } else {
        setLoading(true);
        if (noise_filter) {
          asr_segan(blob)
            .then((res) => {
              setTranscript(res.data);
            })
            .catch((err) => {
              alert(err);
            })
            .then(() => {
              setLoading(false);
            });
        } else {
          asr(blob)
            .then((res) => {
              setTranscript(res.data);
            })
            .catch((err) => {
              alert(err);
            })
            .then(() => {
              setLoading(false);
            });
        }
      }
    }
  }

  return (
    <div className='AsrView'>
      {loading ? (
        <div className='loading'>
          <CircularProgress />
        </div>
      ) : null}
      <div className='container'>
        <div className='title-container'>
          <div className='title'>
            <span id='mobile'>Speech Recognition</span>
            <span id='pc'>Tensorflow Automatic Speech Recognition</span>
          </div>
        </div>
        <div className='data'>
          <div className='left'>
            <div className='extras'>
              <a className='github' href='https://github.com/TensorSpeech/TensorFlowASR' target='_blank'>
                <FontAwesomeIcon icon={faGithub} />
              </a>
              <a className='buymecoffee' href='https://www.buymeacoffee.com/huylenguyen' target='_blank'>
                <img src='https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png' alt='Buy Me A Coffee' />
              </a>
              <a className='kofi' href='https://ko-fi.com/N4N829OY2' target='_blank'>
                <img src={Kofi} alt='Buy Me a Coffee at ko-fi.com' />
              </a>
            </div>
            <div className='content'>
              {/* <div className="error">Demo is currently not available</div> */}
              <div className='streaming'>
                {/*
          <h3>Live streaming mode</h3>
          <Switch
            checked={streaming}
            onChange={e => {
              change_to_streaming(e.target.checked);
            }}
            color="primary"
          />
          */}
                <h4>Subword Conformer (S)</h4>
                {/* <Switch
                  checked={noise_filter}
                  onChange={(e) => {
                    setNoise_filter(e.target.checked);
                  }}
                  color="primary"
                /> */}
                <p />
                <IconButton
                  color='primary'
                  size='small'
                  onClick={() => {
                    setTranscript('');
                  }}>
                  <Clear />
                </IconButton>
              </div>
              <MyTextField
                label='Transcript'
                placeholder=''
                multiline
                margin='normal'
                variant='outlined'
                rowsMax={8}
                value={transcript}
                InputProps={{
                  readOnly: true,
                }}
                type='text'
              />
              <MyReactMic
                record={record}
                key={streaming} // required for react to recognize changes
                streaming={streaming}
                onStop={onStop}
                onData={onData}
                className='visual'
                lineWidth={2}
                backgroundColor='#000000'
                strokeColor='#76FF03'
                timeSlice={timeSlice}
                options={{
                  mimeType: mimeType,
                  bufferSize: bufferSize,
                  sampleRate: sampleRate,
                  audioChannels: channels,
                }}
                constraints={{
                  video: false,
                  audio: true,
                }}
              />
              <div className='option'>
                <audio src={blobUrl} controls />
                <MyFileInput
                  name={blob && blob.name ? truncateString(blob.name, 13) : 'Upload'}
                  id='fileinput'
                  accept='audio/wav'
                  multtple={false}
                  onChange={(e) => {
                    setBlob(e.target.files[0]);
                    setBlobUrl(URL.createObjectURL(e.target.files[0]));
                  }}
                  onSubmit={transcribe}
                />
                <CircleButton onClick={toggle}>
                  {record ? <FontAwesomeIcon icon={faStopCircle} /> : <FontAwesomeIcon icon={faMicrophone} />}
                </CircleButton>
              </div>
            </div>
            <div className='content'>
              <div className='references'>
                <h3>References</h3>
                <ul>
                  <li>
                    <a href='https://arxiv.org/abs/1512.02595' target='_blank'>
                      Deep Speech 2
                    </a>
                  </li>
                  <li>
                    <a href='https://arxiv.org/abs/1211.3711' target='_blank'>
                      RNN Transducer
                    </a>
                  </li>
                  <li>
                    <a href='https://arxiv.org/abs/2005.08100' target='_blank'>
                      Conformer
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='right'>
            <div className='chapter'>
              <hr />
              <p>Subwords Conformer trained on LibriSpeech</p>
              <hr />
            </div>
            {ex1.map((value, key) => {
              return (
                <div className='section' key={key}>
                  <h3>{value.name}</h3>
                  <div className='subsection'>
                    <h4>AUDIO:</h4>
                    <audio src={value.audio} controls />
                  </div>
                  <div className='subsection'>
                    <h4>GROUNDTRUTH:</h4>
                    <p>{value.transcripts.GROUNDTRUTH}</p>
                  </div>
                  <div className='subsection'>
                    <h4>GREEDY:</h4>
                    <p>{value.transcripts.GREEDY}</p>
                  </div>
                  <div className='subsection'>
                    <h4>BEAM SEARCH:</h4>
                    <p>{value.transcripts.BEAMSEARCH}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AsrView;
