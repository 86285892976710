import React from "react";
import "./style.css";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import routes from "../../assets/routes";

function Sidebar(props) {
  let history = useHistory();
  return (
    <div
      className="sidebar"
      style={{
        right: props.visible ? "0" : "-100vw",
      }}
    >
      {routes.map((value, idx) => {
        return (
          <button
            onClick={() => {
              history.push(value.path);
              props.closeSidebar();
            }}
          >
            <FontAwesomeIcon icon={faChevronRight} />
            {value.name}
          </button>
        );
      })}
    </div>
  );
}

export default Sidebar;
