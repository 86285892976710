import React, { useState, useRef, useEffect } from "react";
import MyMediaRecorder from "./mediaRecoder";

function MyReactMic(props) {
  var [myMediaRecorder, setMyMediaRecorder] = useState(null);

  useEffect(() => {
    setMyMediaRecorder(
      new MyMediaRecorder(
        props.onStart,
        props.onData,
        props.onStop,
        props.timeSlice,
        props.options,
        props.constraints
      )
    );
  }, []);

  useEffect(() => {
    if (props.record) {
      if (myMediaRecorder) {
        myMediaRecorder.startRecording();
      }
    } else {
      if (myMediaRecorder) {
        myMediaRecorder.stopRecording();
      }
    }
  }, [props.record]);

  return (
    <div style={{ "display": "none" }} />
  );
}

MyReactMic.defaultProps = {
  onStart: null,
  onStop: null,
  onData: null,
  options: {
    mimeType: "audio/wav",
    bufferSize: 4096,
    sampleRate: 44100,
    audioChannels: 2
  },
  timeSlice: 1000,
  constraints: {
    video: false,
    audio: true
  }
};

export default MyReactMic;
