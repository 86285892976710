import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/header/header";

import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import routes from "./assets/routes";

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "Source Sans Pro",
      "Nunito",
      "Quicksand",
      "Open Sans",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif"
    ].join(","),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightBold: 400,
    fontSize: 16
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Header />
        <Switch>
          {routes.map((value, index) => {
            return (
              <Route
                exact
                path={value.path}
                key={index}
                render={props => <value.component {...props} />}
              />
            );
          })}
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
