import HomeView from "../views/home/HomeView";
// import AboutView from "../views/about/AboutView";
import AsrView from "../views/asr/AsrView";

const routes = [
  {
    path: "/",
    component: HomeView,
    name: "Home",
  },
  // {
  //   path: "/about",
  //   component: AboutView,
  //   name: "About Me",
  // },
  {
    path: "/asr",
    component: AsrView,
    name: "Automatic Speech Recognition",
  },
];

export default routes;
